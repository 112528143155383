import { Component, Prop, Watch } from 'vue-property-decorator';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowModule from '@/store/modules/workflowModule';
import TasqLightningChart from './TasqLightningChart.vue';
import TasqLightningPlungerChart from './TasqPlungerLightningChart.vue';
import TasqLightningProductionChart from './TasqLightningProductionChart.vue';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import gptModule from '../../store/modules/gptModule';
import GptMixin from '@/components/gpt/GptMixin';
import axiosClient from '@/lib/rest/axiosClient';
import assetsModule from '@/store/modules/assetsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import {
  SHOW_ENDPOINT_RESPONSE_ALERT,
  TASQ_OFF_TARGET_TYPE,
  WELL_CLICK_EVENT,
  HISTORY_SEARCH_UPDATED_EVENT,
} from '@/lib/constants';
import { getNameByEmail } from '@/utils/users';
import DataLoading from '@/lib/mixins/dataLoading';
import { Debounce } from 'vue-debounce-decorator';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import userPreference from '@/lib/userPreference';

@Component
export default class WellSignalViewMixin extends mixins(GptMixin, DataLoading) {
  @Prop({ type: Boolean, default: false }) isMobileViewComponent!: boolean;

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  signalChartTime = 60;

  chartRefreshKey = 0;

  signalHighlightComment = '';

  hideChartTimes = false;

  legendRefreshKey = 0;

  legendRefreshPlungerKey = 0;

  legendRefreshKeyProd = 0;

  showFullScreenSignalsChart = false;

  showFullScreenPlungerSignalsChart = false;

  showFullScreenProductionChart = false;

  isLoadingSignals = true;

  showChartModal: any = false;

  isLoadingPlungerSignals = true;

  isLoadingProductionData = true;

  failedToPullPlungerData = false;

  showWellEventsLocal = false;

  localHistoryEvent = true;

  failedToPullProductionData = false;

  chartsLoading = false;

  customSignalRange: any = { startDate: null, endDate: null };

  defermentType = '';

  selectedDates = [] as any;

  wellEventDetails: any = {
    action: {},
    createdBy: '',
    createdDate: '',
    comment: '',
    jobType: '',
  };

  wellEventDetailsDefault: any = {
    action: {},
    createdBy: '',
    createdDate: '',
    comment: '',
    jobType: '',
  };

  showWellEventDetail = false;

  defermentLabelingModal = false;

  get signalLabelingModal() {
    return tasqSignalsModule.signalLabelingModal;
  }

  get prodLabelingModal() {
    return tasqProductionDataChartModule.prodLabelingModal;
  }

  resizeLoading = false;

  get signalHighlightStart() {
    return tasqSignalsModule.signalHighlightStart;
  }

  get signalHighlightEnd() {
    return tasqSignalsModule.signalHighlightEnd;
  }

  get prodHighlightStart() {
    return tasqProductionDataChartModule.prodHighlightStart;
  }

  get prodHighlightEnd() {
    return tasqProductionDataChartModule.prodHighlightEnd;
  }

  showRelabelPopup(eventKey) {
    console.log(eventKey);
    if (eventKey === 'production') {
      tasqProductionDataChartModule.setProdLabelingModal(!this.prodLabelingModal);
      (this.$refs.myChildRefProd as TasqLightningProductionChart).resetRectangleSeries();
      if (this.prodLabelingModal) {
        this.$nextTick(() => {
          (this.$refs.myChildRefProd as TasqLightningProductionChart).intilizeSignalHighlight();
        });
      }
      // this.retrainTasq();
    } else if (eventKey === 'signals') {
      tasqSignalsModule.setSignalLabelingModal(!this.signalLabelingModal);
      (this.$refs.myChildRef as TasqLightningChart).resetRectangleSeries();
      if (this.signalLabelingModal) {
        this.$nextTick(() => {
          (this.$refs.myChildRef as TasqLightningChart).intilizeSignalHighlight();
        });
      }
    }
  }

  retrainTasq() {
    if (this.activeTasq && !this.isBatchResponding) {
      this.defermentLabelingModal = true;
    }
  }

  parseDate(dateString) {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');

    // Months in JavaScript Date are zero-indexed (0 - 11), so subtract 1 from the month value
    return new Date(year, month - 1, day, hours, minutes);
  }

  async submitSignalRange() {
    console.log(this.signalHighlightStart);
    console.log(typeof this.signalHighlightStart);
    const start = this.parseDate(this.signalHighlightStart);
    const end = this.parseDate(this.signalHighlightEnd);
    tasqSignalsModule.setSignalLabelingModal(false);
    await this.postLabelRange(start, end);
    this.removeRangeSignalSelection();
  }

  async postLabelRange(start, end, type = 'signal_label') {
    try {
      const filterPayload = {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        node_name: this.activeTasq.wellName,
        node_level: this.activeTasq.level,
        value_type: 'label',
        source_type: type,
        comment: this.signalHighlightComment,
      };

      let startDate =  start
      let endDate  = end

      if (startDate > endDate) {
        const temp = startDate;
        startDate = endDate;
        endDate = temp;
      }

      this.removeRangeSignalSelection();
      const { data: existingRecord }: any = await axiosClient.get('/feature-store/', {
        params: filterPayload,
      });
      if (existingRecord && existingRecord.length) {
        await axiosClient.put('/feature-store/', {
          match: filterPayload,
          data: {
            start_data: startDate,
            end_date: endDate,
          },
        });
      } else {
        await axiosClient.post('/feature-store/', {
          ...filterPayload,
          time: new Date().toISOString(),
          data: {
            start_data: startDate,
            end_date: end,
          },
        });
      }

      if (filterPayload['comment'].length > 1) {
        const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);

        const metaData = {
          body: filterPayload['comment'],
          start_date: startDate,
          end_date: endDate,
          wellMetaData: {
            ...(this.operatorName !== 'demo' && {
              operator_name: operatorDetails['operatorName'],
            }),
            ...(this.operatorName !== 'demo' && {
              operator_id: operatorDetails['operatorID'],
            }),
            nodeid: this.activeTasq.wellName,
            level: this.activeTasq && this.activeTasq.level ? this.activeTasq.level.toLowerCase() : 'well'
          },
        };

        const payload = {
          ...metaData,
        };
        console.log(payload);
        await gptModule.postGptComment(payload);
        delete payload.body;
        // await gptModule.getGptLastActions(payload);
      }
      this.signalHighlightComment = '';

      this.$tasqAlert({
        title: 'Success',
        message: 'Historical entry was added successfully.',
        type: 'success',
      });
    } catch (error) {
      console.log(error);
      this.$tasqAlert({
        title: 'Error',
        message: 'Historical entry was not added successfully',
        type: 'error',
      });
    }
  }

  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  resizeWindow() {
    this.resizeLoading = true;
    this.$nextTick(() => {
      this.resizeLoading = false;
    });
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await sleep(0.1);
    this.defermentLabelingModal = true;
  }

  async submitDeferment() {
    this.defermentLabelingModal = false;
    this.$tasqAlert({
      title: 'Success',
      message: 'Retrain successfully.',
      type: 'success',
    });
  }


  get selectedCustomRange() {
    // console.log(this.customSignalRange);
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get tasqListLevel() {
    if (
      tasqsListModule.tasqListLevel.toLowerCase() == 'well' ||
      (this.activeTasq &&
        tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' &&
        this.activeTasq.level.toLowerCase() == 'well')
    ) {
      return 'Well';
    }
    return tasqsListModule.tasqListLevel.toLowerCase();
    //   if (this.activeTasq == null) {
    //    return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }

  get isHistorySectionOpen() {
    return tasqsListModule.isHistorySectionOpen;
  }

  get activeTasq() {
    if (tasqsListModule.tasqListLevel.toLowerCase() === 'workticket') {
      console.log(tasqsListModule.activeWorkTicket);
      return tasqsListModule.activeWorkTicket;
    }
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (
      tasqsListModule.activeTasq != null &&
      this.$route.params.id != null &&
      this.$route.query.type == 'producing' &&
      tasqsListModule.activeTasq.level.toLowerCase() == 'pad'
    ) {
      return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    return assetsModule.activeTasq as TasqJob;
  }

  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get didPlungerSignalsLoaded() {
    return tasqSignalsModule.didLoadAllPlungerSignals;
  }

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  removeRangeProdSelection() {
    tasqProductionDataChartModule.setProdLabelingModal(false);
    (this.$refs.myChildRefProd as TasqLightningProductionChart).resetRectangleSeries();
    tasqProductionDataChartModule.setProdLabelingModal(false);
  }

  removeRangeSignalSelection() {
    tasqSignalsModule.setSignalLabelingModal(false);
    (this.$refs.myChildRef as TasqLightningChart).resetRectangleSeries();
    tasqSignalsModule.setSignalLabelingModal(false);
    this.signalHighlightComment = '';
  }

  didSelectResetChart(type) {
    if (type == 'tasq-lightning-chart') {
      (this.$refs.myChildRef as TasqLightningChart).didSelectResetChart();
      (this.$refs.myChildRef as TasqLightningChart).resetRectangleSeries();
      tasqSignalsModule.setSignalLabelingModal(false);
    } else if (type == 'tasq-lightning-chart-full') {
      (this.$refs.myChildRefFull as TasqLightningChart).didSelectResetChart();
      (this.$refs.myChildRefFull as TasqLightningChart).resetRectangleSeries();
      tasqSignalsModule.setSignalLabelingModal(false);
    } else if (type == 'tasq-lightning-chart-prod') {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).didSelectResetChart();
    } else if (type == 'tasq-lightning-chart-prod-full') {
      (this.$refs.myChildRefProdFull as TasqLightningProductionChart).didSelectResetChart();
    } else if (type == 'tasq-plunger-lightning-chart') {
      (this.$refs.myPlungerChartRef as TasqLightningPlungerChart).didSelectResetChart();
    } else if (type == 'tasq-plunger-lightning-chart-full') {
      (this.$refs.plungerChartRefFull as TasqLightningPlungerChart).didSelectResetChart();
    }
  }

  isSignalSelected(signal_name) {
    return tasqSignalsModule.selectedSignals.indexOf(signal_name) > -1;
  }

  isPlungerSignalSelected(signal_name) {
    return tasqSignalsModule.selectedPlungerSignals.indexOf(signal_name) > -1;
  }

  isSignalSelectedProd(signal_name) {
    return tasqProductionDataChartModule.selectedSignals.indexOf(signal_name) > -1;
  }

  updateChartToMatchLegend() {
    if (this.showFullScreenSignalsChart) {
    } else {
      (this.$refs.myChildRef as TasqLightningChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully');
    }
  }

  updatePlungerChartToMatchLegend() {
    if (this.showFullScreenSignalsChart) {
    } else {
      (this.$refs.myPlungerChartRef as TasqLightningPlungerChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully');
    }
  }

  updateProdChartToMatchLegend() {
    if (this.showFullScreenProductionChart) {
    } else {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully prod');
    }
  }

  selectSignalOnLegend(data) {
    const { name, index, type } = data;
    if (type === 'wellSignals') {
      this.didSelectSignalOnLegend(index, name);
    } else if (type === 'plungerSignals') {
      this.didSelectPlungerSignalOnLegend(index, name);
    } else if (type === 'ProductionSignals') {
      this.didSelectSignalOnLegendProd(index, name);
    }
  }

  didSelectSignalOnLegendProd(index, signal) {
    console.log(index);
    console.log(signal);
    if (!this.showFullScreenProductionChart) {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).initializePage(index, signal);
      tasqProductionDataChartModule.selectSignal(signal);
      this.legendRefreshKeyProd += 1;
    } else {
      (this.$refs.myChildRefProdFull as TasqLightningProductionChart).initializePage(index, signal);
      tasqProductionDataChartModule.selectSignal(signal);
      this.legendRefreshKeyProd += 1;
    }
  }

  didSelectSignalOnLegend(index, signal) {
    if (!this.showFullScreenSignalsChart) {
      (this.$refs.myChildRef as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectSignal(signal);
      this.legendRefreshKey += 1;
    } else {
      (this.$refs.myChildRefFull as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectSignal(signal);
      this.legendRefreshKey += 1;
    }
  }

  didSelectPlungerSignalOnLegend(index, signal) {
    if (!this.showFullScreenPlungerSignalsChart) {
      (this.$refs.myPlungerChartRef as TasqLightningPlungerChart).initializePage(index, signal);
      tasqSignalsModule.selectPlungerSignal(signal);
      this.legendRefreshPlungerKey += 1;
    } else {
      (this.$refs.plungerChartRefFull as TasqLightningPlungerChart).initializePage(index, signal);
      tasqSignalsModule.selectPlungerSignal(signal);
      this.legendRefreshPlungerKey += 1;
    }
  }

  didSelectProductionFullScreen() {
    this.showFullScreenProductionChart = !this.showFullScreenProductionChart;
    this.updateProdChartToMatchLegend();
  }

  didSelectSignalsFullScreen() {
    tasqSignalsModule.setSignalLabelingModal(false);
    this.showFullScreenSignalsChart = !this.showFullScreenSignalsChart;
    this.updateChartToMatchLegend();
  }

  didSelectPlungerSignalsFullScreen() {
    this.showFullScreenPlungerSignalsChart = !this.showFullScreenPlungerSignalsChart;
    this.updatePlungerChartToMatchLegend();
  }

  addTasqDidSelectAssetTasq() {}

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
    return this.$route.query.type;
  }

  get signalDescriptions(): any {
    return tasqSignalsModule.signalDescriptions;
  }

  get plungerSignalDescriptions(): any {
    return tasqSignalsModule.plungerSignalDescriptions;
  }

  get currentSignals(): any {
    return tasqSignalsModule.currentSignals;
  }

  get currentPlungerSignals(): any {
    return tasqSignalsModule.currentPlungerSignals;
  }

  get productionDataDict(): any {
    return tasqProductionDataChartModule.productionDataDict;
  }

  get productionType() {
    return getConfigEnv('PRODUCTION_TYPE');
  }

  get enableTestVolumes() {
    return getConfigEnv('ENABLE_TEST_VOLUMES');
  }

  get legendSignalsProd(): any {
    const legendSignals: any = [];

    for (const [key, value] of Object.entries(this.productionDataDict)) {
      if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
        continue;
      }

      const allowedKeys = ['water_rate', 'gas_rate', 'oil_rate'];
      if (this.productionType === 'boe') {
        allowedKeys.push('boe_rate');
      }
      if (this.enableTestVolumes) {
        allowedKeys.push('water_rate_fc');
        allowedKeys.push('water_rate_source');
        allowedKeys.push('oil_rate_fc');
        allowedKeys.push('oil_rate_source');
        allowedKeys.push('gas_rate_fc');
        allowedKeys.push('gas_rate_source');
      }

      if (!allowedKeys.includes(key)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077f0';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      } else if (key == 'boe_rate') {
        color = '#FFD977';
      } else if (key == 'water_rate_fc') {
        color = '#66B2FF'; // RGB(102, 178, 255)
      } else if (key == 'water_rate_source') {
        color = '#004EA2'; // RGB(0, 78, 162)
      } else if (key === 'oil_rate_fc') {
        color = '#4BFFD2'; // RGB(75, 255, 210)
      } else if (key === 'oil_rate_source') {
        color = '#1EB496'; // RGB(30, 180, 150)
      } else if (key === 'gas_rate_fc') {
        color = '#FF6993'; // RGB(255, 105, 147)
      } else if (key === 'gas_rate_source') {
        color = '#CD4E74'; // RGB(205, 78, 116)
      }

      legendSignals.push({
        name: key,
        selected: this.isSignalSelectedProd(key),
        color,
        type: 'PRODUCTION',
      });
    }
    return legendSignals;
  }

  get legendSignals(): any {
    const legendSignals: any = [];
    for (let x = 0; x < this.currentSignals.length; x++) {
      legendSignals.push({
        name: this.currentSignals[x].name,
        selected: this.isSignalSelected(this.currentSignals[x].name),
        color: this.currentSignals[x].color,
        type: 'SIGNAL',
      });
    }

    for (const [key, value] of Object.entries(this.productionDataDict)) {
      // @ts-ignore
      const allowedKeys = ['water_rate', 'gas_rate', 'oil_rate'];
      if (this.productionType === 'boe') {
        allowedKeys.push('boe_rate');
      }

      if (!allowedKeys.includes(key)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      } else if (key == 'boe_rate') {
        color = '#FFD977';
      }
      legendSignals.push({
        name: key,
        selected: this.isSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }
    return legendSignals;
  }

  get legendPlungerSignals(): any {
    const legendSignals: any = [];
    for (let x = 0; x < this.currentPlungerSignals.length; x++) {
      legendSignals.push({
        name: this.currentPlungerSignals[x].name,
        selected: this.isPlungerSignalSelected(this.currentPlungerSignals[x].name),
        color: this.currentPlungerSignals[x].color,
        type: 'PLUNGER',
      });
    }

    for (const [key, value] of Object.entries(this.productionDataDict)) {
      // @ts-ignore
      const allowedKeys = ['water_rate', 'gas_rate', 'oil_rate'];
      if (this.productionType === 'boe') {
        allowedKeys.push('boe_rate');
      }

      if (!allowedKeys.includes(key)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      } else if (key == 'boe_rate') {
        color = '#FFD977';
      }
      legendSignals.push({
        name: key,
        selected: this.isPlungerSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }

    return legendSignals;
  }

  async changeChartTimes(val) {
    await userPreference.setItem('signalChartTime',val)
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    await this.loadSignalData();
  }

  @Debounce(1000)
  showEventCommentPopup(comment) {
    if (comment) {
      this.wellEventDetails = {
        createdBy: comment.Username ? getNameByEmail(comment.Username) : 'N/A',
        createdDate: comment.time,
        ...comment,
        jobType: comment.JobType || comment.PredictionType,
      };
      this.showWellEventDetail = true;
    }
  }

  async loadProductionData() {
    tasqProductionDataChartModule.resetProductionData();
    this.isLoadingProductionData = true;
    this.failedToPullProductionData = false;
    const production_promises: any[] = [];
    console.log('prod request was made');
    production_promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
    Promise.all(production_promises).then(
      async () => {
        this.isLoadingProductionData = false;
        this.failedToPullProductionData = false;

        tasqsListModule.setIsInitialPageLoad(false);
        // await sleep(3500);
        this.hideChartTimes = false;
        console.log('prod request was done');
      },
      async (err) => {
        // console.log('Error: Failed to pull production data');
        console.error(
          `Error: Failed to pull production data on ${this.activeTasq ? this.activeTasq.wellName : ''}, for ${
            this.signalChartTime
          } days`
        );
        console.log(err);
        this.failedToPullProductionData = true;
        await sleep(3500);
        this.hideChartTimes = false;
      }
    );
  }

  async loadSignalData() {
    tasqSignalsModule.setPlungerController('abort')
    tasqSignalsModule.setSignalController('abort')
    this.hideChartTimes = true;
    this.wellEventDetails = this.wellEventDetailsDefault;
    this.failedToPullPlungerData = false;
    this.isLoadingPlungerSignals = true;
    this.isLoadingProductionData = true;
    this.isPreviewingModel = false;
    defermentLabelingModule.setModelUUID(null);
    this.isLoadingSignals = true;
    this.chartsLoading = false;
    this.failedToPullProductionData = false;
    tasqSignalsModule.setPlungerSignalDescriptions([]);
    tasqsListModule.setFromNowBack(0);
    tasqSignalsModule.resetSignals();
    tasqSignalsModule.resetPlungerSignals();
    tasqProductionDataChartModule.resetProductionData();
    this.customSignalRange = { startDate: null, endDate: null };
    tasqsListModule.setSignalChartTime(this.signalChartTime);

    await this.loadProductionData();
    await sleep(2000)

    if (!this.isMobileViewComponent) {
      const signalPromises: any[] = [];
      signalPromises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
      Promise.all(signalPromises).then(
        async () => {
          this.isLoadingSignals = false;
        },
        (err) => {
          console.error(
            `Error: Failed to signal  data on ${this.activeTasq ? this.activeTasq.wellName : ''}, for ${
              this.signalChartTime
            } days`
          );
          console.log('Error:');
          console.log(err);
        }
      );

      await sleep(1000);

      const plungerSignalPromises: any[] = [];
      if (getConfigEnv('OPERATOR_LOWERCASED') === 'pdc') {
        plungerSignalPromises.push(tasqSignalsModule.getPlungerSignalsForTasq(this.signalChartTime));
      }

      Promise.all(plungerSignalPromises)
        .then(async () => {
          this.isLoadingPlungerSignals = false;
        })
        .catch((err) => {
          console.log(err);
          // this.failedToPullPlungerData = (true);
          this.$nextTick(() => {
            this.isLoadingPlungerSignals = false;
          });
        });
    }
  }

  async fetchWellSignals() {
    this.chartTimes = this.$getConst('CHART_TIMES');
    let storedSignalChartTime: any = await userPreference.getItem('signalChartTime');
    if(storedSignalChartTime){
      this.signalChartTime =  storedSignalChartTime;
    }else{
      this.signalChartTime = 60;
    }

    await this.loadSignalData()
  }

  async created() {
    this.dataLoading = true;
    tasqProductionDataChartModule.setSignalHighlightEnd(null)
    tasqProductionDataChartModule.setSignalHighlightStart(null)
    defermentLabelingModule.setModelUUID(null);
    if (this.productionType === 'oil') {
      this.defermentType = 'oil_rate';
    } else if (this.productionType === 'gas') {
      this.defermentType = 'gas_rate';
    } else if (this.productionType === 'boe') {
      this.defermentType = 'boe_rate';
    }

    this.showWellEventsLocal = this.showWellEvents;
    tasqSignalsModule.setSignalLabelingModal(false);
    tasqProductionDataChartModule.setProdLabelingModal(false);
    tasqsListModule.setIsInitialPageLoad(true);
    this.isLoadingSignals = true;
    this.failedToPullProductionData = false;
    this.failedToPullPlungerData = false;

    this.$eventBus.$on(WELL_CLICK_EVENT, (comment) => {
      this.showEventCommentPopup(comment);
    });

    this.$eventBus.$on(HISTORY_SEARCH_UPDATED_EVENT, this.handleHistorySearchUpdatedEvent);
    await this.fetchWellSignals();
  }

  beforeDestroy() {
    this.$eventBus.$off(HISTORY_SEARCH_UPDATED_EVENT, this.handleHistorySearchUpdatedEvent);
  }

  handleHistorySearchUpdatedEvent(filteredWellHistoryEvents) {
    this.localHistoryEvent = false;
    this.$nextTick(() => {
      this.showWellEventsLocal = Boolean(filteredWellHistoryEvents && filteredWellHistoryEvents.length);
    });
  }

  timer;

  handleScroll() {
    console.log('scrolling');
    if ((this.$refs.myChildRef as TasqLightningChart) != null) {
      (this.$refs.myChildRef as TasqLightningChart).reloadChartOnScroll();
    }
    if ((this.$refs.myChildRefProd as TasqLightningProductionChart) != null) {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).reloadChartOnScroll();
    }

    if ((this.$refs.myPlungerChartRef as TasqLightningPlungerChart) != null) {
      (this.$refs.myPlungerChartRef as TasqLightningPlungerChart).reloadChartOnScroll();
    }

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    // foo.on('error', function(this: Foo, err: any) {
    this.timer = setTimeout(() => {
      if ((this.$refs.myChildRefProd as TasqLightningProductionChart) != null) {
        (this.$refs.myChildRefProd as TasqLightningProductionChart).refresh();
      }
      if ((this.$refs.myChildRef as TasqLightningChart) != null) {
        (this.$refs.myChildRef as TasqLightningChart).refresh();
      }
      if ((this.$refs.myPlungerChartRef as TasqLightningPlungerChart) != null) {
        (this.$refs.myPlungerChartRef as TasqLightningPlungerChart).refresh();
      }
    }, 150);
  }

  previewPercentage = 25;

  retrainComment = '';

  isSavingModel = false;

  isPreviewingModel = false;

  get modelUUID() {
    return defermentLabelingModule.ModelUUID;
  }

  async runDefermentCalc() {
    const dates = this.productionDataDict.date;
    const start = tasqProductionDataChartModule.prodHighlightStart;
    const end = tasqProductionDataChartModule.prodHighlightEnd;
    this.retrainComment = '';
    this.previewPercentage = 25;

    this.isPreviewingModel = true;

    const convertToDateFormat = (input) => {
      const [datePart, timePart] = input.split(', ');

      let [day, month, year] = datePart.split('/');

      if (input.toLowerCase().includes('am') || input.toLowerCase().includes('pm')) {
          // Format: 7/20/2023, 12:04 PM
          const [monthStr, dayStr, yearTime] = datePart.split('/');
          const [yearStr, time, period] = yearTime.split(' ');

          monthStr.padStart(2, '0');
          dayStr.padStart(2, '0');

          const formattedDate = `${yearStr}-${monthStr}-${dayStr}`;
          return formattedDate;
      } else {
          // Format: 17/03/2023, 21:50
          month = month.padStart(2, '0');
          day = day.padStart(2, '0');

          return `${year}-${month}-${day}`;
      }
  }

    let startDate = convertToDateFormat(start);
   let endDate = convertToDateFormat(end);

    // Swap the start and end dates if necessary
    let isReverseOrder = false || tasqProductionDataChartModule.isSignalHighlightReverseOrder
    console.log(tasqProductionDataChartModule.isSignalHighlightReverseOrder);

      if(startDate === endDate && dates && dates.length){
        endDate =  isReverseOrder ? dates[0] : dates[dates.length - 1]
    }
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      const temp = startDate;
      isReverseOrder = true
      startDate = endDate;
      endDate = temp;
    }


    console.log(endDate);
    this.isPreviewingModel = true;
    const mappedMasks = {
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const mappedData: any[] = [];
      // console.log(this.productionDataDict.date);
      if (this.productionDataDict && this.productionDataDict.date) {
        // eslint-disable-next-line block-scoped-var, no-plusplus
        for (let f = 0; f < this.productionDataDict.date.length; f++) {
          mappedData.push({
            date: this.productionDataDict.date[f],
            // eslint-disable-next-line block-scoped-var
            [this.defermentType]: parseFloat(this.productionDataDict[this.defermentType][f]),
          });
        }

        await sleep(500);

        this.previewPercentage = 50;

        await defermentLabelingModule.getFitRun({
          data: mappedData,
          nodeid: this.activeTasq.wellName,
          value_type: this.defermentType,
          mask: mappedMasks,
        });
        // await this.sleep(10000)
        if (this.$refs.myChildRefProd as TasqLightningProductionChart) {
          (this.$refs.myChildRefProd as TasqLightningProductionChart).addPreviewTarget();
        }
      }

      await sleep(500);

      this.previewPercentage = 75;

      await sleep(1000);

      this.previewPercentage = 100;

      // this.addNewDefermentLabelsToChart();
      this.isPreviewingModel = false;
    } catch (error) {
      this.isPreviewingModel = false;
      console.log(error);
      this.$tasqAlert({
        title: 'Error',
        message: 'It is not possible to retrain the model at this moment, please try later',
        type: 'error',
      });
    }
  }

  async saveDefermentCalc() {
    try {
      this.isSavingModel = true;
      await defermentLabelingModule.saveFitRun({
        ModelUUID: this.modelUUID,
      });

      if (this.retrainComment.length > 2) {
        const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);

        const metaData = {
          useAll: false,
          body: this.retrainComment,
          type: 'Retrained',
          wellMetaData: {
            ...(this.operatorName !== 'demo' && {
              operator_name: operatorDetails['operatorName'],
            }),
            ...(this.operatorName !== 'demo' && {
              operator_id: operatorDetails['operatorID'],
            }),
            nodeid: this.activeTasq.wellName,
            level: this.activeTasq && this.activeTasq.level ? this.activeTasq.level.toLowerCase() : 'well'
          },
        };

        const payload = {
          query: '',
          stream: false,
          ...metaData,
        };
        console.log(payload);
        await gptModule.postGptComment(payload);
        delete payload.body;
        // await gptModule.getGptLastActions(payload);
      }
      this.retrainComment = '';



      defermentLabelingModule.setModelUUID(null);
      this.$tasqAlert({
        title: 'Success',
        message: 'Retrain successfully.',
        type: 'success',
      });
      await this.loadProductionData();
      this.isSavingModel = false;

      this.$emit('save');
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'It is not possible to retrain the model at this moment, please try later',
        type: 'error',
      });
    }finally{
      this.isSavingModel = false;
      defermentLabelingModule.setModelUUID(null);
      if(this.$refs.myChildRefProd){
        (this.$refs.myChildRefProd as TasqLightningProductionChart).resetRectangleSeries();
      }
    }

  }

  @Watch('showWellEventsLocal')
  onUpdateShowWellEvents(newValue) {
    tasqProductionDataChartModule.setShowWellEvents(newValue);
    if (
      (this.$refs.myChildRefProd as TasqLightningProductionChart) &&
      !this.isHistorySectionOpen &&
      this.localHistoryEvent
    ) {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).addHistoryToChart(newValue);
    } else {
      this.localHistoryEvent = true;
    }
  }
}
